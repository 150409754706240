import { Box, IconButton, Modal } from '@mui/material';
import { useCallback } from 'react';

import { useTacticalAnalysesFilters } from 'api/recording/use-tactical-analyses-filters';
import { TIMELINE_DEFAULT_FILTERS } from 'api/recording/useEpisodesWithFilters/useTacticalAnalysesPlaylistItems';
import { MultiAnalysisFiltersPlaylist } from 'pages/playlist-detail/components/add-multiple-clips/components/multimatch-filters-modal/components/multi-analysis-filters-playlist/MultiAnalysisFiltersPlaylist';
import { RecordingsFiltersContent } from 'pages/playlist-detail/components/add-multiple-clips/components/recordings-filters-content/RecordingsFiltersContent';
import { RecordingsFiltersHeader } from 'pages/playlist-detail/components/add-multiple-clips/components/recordings-filters-header/RecordingsFiltersHeader';
import { SelectRecordings } from 'pages/playlist-detail/components/add-multiple-clips/components/select-recordings/SelectRecordings';
import {
  usePlaylistMultimatchAppliedFilters,
  useSetPlaylistMultimatchAppliedFilters,
} from 'pages/playlist-detail/store/atoms';
import IconClose from 'shared/components/icons/icon-close';
import { RecordingByName, RecordingsFilters } from 'shared/types/recording/types';
import { useSavePlaylistMultimatchFiltersAppliedPreset } from 'shared/types/user-preset/hooks/use-save-playlist-multimatch-filters-applied-preset';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  playlistId: string;
}

export const MultimatchFiltersModal = ({ playlistId, isOpen, onClose }: Props) => {
  const appliedFilters = usePlaylistMultimatchAppliedFilters(playlistId);
  const setAppliedFilters = useSetPlaylistMultimatchAppliedFilters(playlistId);
  const { isLoading, data } = useTacticalAnalysesFilters(appliedFilters.recordingIds);
  const saveMultimatchAppliedFiltersPreset = useSavePlaylistMultimatchFiltersAppliedPreset();

  const handleUpdateMultimatchAppliedFilters = useCallback(
    (filters: RecordingsFilters) => {
      setAppliedFilters(filters);
      saveMultimatchAppliedFiltersPreset(playlistId, filters);
    },
    [setAppliedFilters, saveMultimatchAppliedFiltersPreset, playlistId],
  );

  const handleSetRecordings = useCallback(
    (currentRecordingsSelection: RecordingByName[], recordingsSelection: RecordingByName[]) => {
      const filters = currentRecordingsSelection.some((r) => !recordingsSelection.includes(r)) ? {} : appliedFilters;

      handleUpdateMultimatchAppliedFilters({ ...filters, recordingIds: recordingsSelection.map((r) => r.id) });
    },
    [appliedFilters, handleUpdateMultimatchAppliedFilters],
  );

  return (
    <Modal
      disableEnforceFocus
      open={isOpen}
      onClose={onClose}
      sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
    >
      <Box
        position='absolute'
        top='3rem'
        maxWidth='80rem'
        width='90%'
        maxHeight='calc(100vh - 6rem)'
        bgcolor='common.white'
        borderRadius='8px'
        sx={{
          overflowY: 'auto',
        }}
        data-testid='multimatch-filters-modal'
      >
        <Box position='absolute' right={({ spacing }) => spacing(2)} top={({ spacing }) => spacing(2)}>
          <IconButton size='small' onClick={onClose}>
            <IconClose size='small' />
          </IconButton>
        </Box>
        <RecordingsFiltersHeader>
          <SelectRecordings recordingIds={appliedFilters.recordingIds} onChange={handleSetRecordings} />
        </RecordingsFiltersHeader>
        <RecordingsFiltersContent>
          <MultiAnalysisFiltersPlaylist
            filtersData={data ?? TIMELINE_DEFAULT_FILTERS}
            isLoadingRecordings={isLoading && appliedFilters.recordingIds.length > 0}
            key={appliedFilters.recordingIds.join(',')}
            onClose={onClose}
            playlistId={playlistId}
            appliedFilters={appliedFilters}
            onUpdate={handleUpdateMultimatchAppliedFilters}
          />
        </RecordingsFiltersContent>
      </Box>
    </Modal>
  );
};
