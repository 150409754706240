import { Box, IconButton, Modal } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useTacticalAnalysisFilteredItems } from 'api/recording/use-tactical-analysis-filtered-items';
import { MultiAnalysisFilters } from 'features/multi-analysis-filters/MultiAnalysisFilters';
import { useMetrics } from 'kognia/metrics/hooks/use-metrics';
import { FiltersModalContent } from 'pages/tactical-analysis/components/tactical-analysis/filters/components/filters-modal/styled';
import { useTacticalAnalysisAvailableFilters } from 'pages/tactical-analysis/hooks/use-tactical-analysis-available-filters';
import { useTacticalAnalysisId } from 'pages/tactical-analysis/hooks/use-tactical-analysis-id';
import IconClose from 'shared/components/icons/icon-close';
import { Episode, MatchTeams } from 'shared/types';
import { MetricsData, MetricsNames } from 'shared/types/metrics';
import { RecordingsFilters } from 'shared/types/recording/types';

interface Props {
  episodeCount: number;
  episodes: Episode[];
  isOpen: boolean;
  onClose: () => void;
  onApply: (appliedFilters: RecordingsFilters, episodes: Episode[]) => void;
  recordingId: string;
  teams: MatchTeams;
  appliedFilters: RecordingsFilters;
  clearFilters: () => void;
}

const FiltersModal = ({ isOpen, onClose, recordingId, onApply, appliedFilters, clearFilters }: Props) => {
  const { t } = useTranslation();
  const tacticalAnalysisId = useTacticalAnalysisId(recordingId);
  const { getFilters, isPending, data } = useTacticalAnalysisFilteredItems();
  const availableFilters = useTacticalAnalysisAvailableFilters(recordingId);
  const { pushEvent } = useMetrics();

  const handleOnApply = useCallback(
    (filters: RecordingsFilters) => {
      pushEvent<MetricsData[MetricsNames.TACTICAL_ANALYSIS_APPLY_FILTER]>(MetricsNames.TACTICAL_ANALYSIS_APPLY_FILTER, {
        filter: JSON.stringify(filters),
      });
      onApply(filters, data);
    },
    [data, pushEvent, onApply],
  );

  const handleOnUpdate = useCallback(
    (filters: RecordingsFilters) => {
      getFilters(tacticalAnalysisId, filters);
    },
    [tacticalAnalysisId, getFilters],
  );

  const handleGetFiltersWithTacticalAnalysisId = useCallback(() => {
    return (params: RecordingsFilters) => {
      getFilters(tacticalAnalysisId, params);
    };
  }, [tacticalAnalysisId, getFilters]);

  const handleClearFilters = useCallback(() => {
    clearFilters();
    onClose();
  }, [clearFilters, onClose]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby='filters-title'
      aria-describedby='filters-description'
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <FiltersModalContent>
        <Box position='absolute' top={({ spacing }) => spacing(1)} right={({ spacing }) => spacing(1)}>
          <IconButton size='small' onClick={onClose}>
            <IconClose isButton color='secondary' size='small' />
          </IconButton>
        </Box>
        <MultiAnalysisFilters
          applyButtonLabel={t('timeline:filters-apply-count', { count: data.length ?? 0 })}
          applyButtonDefaultLabel={t('timeline:filters-apply')}
          resetButtonLabel={t('recordings:filters.reset-all')}
          filtersData={availableFilters}
          appliedFilters={appliedFilters}
          isDisabled={false}
          isLoading={isPending}
          isLoadingFilters={false}
          recordingIds={[recordingId]}
          onApply={handleOnApply}
          onUpdate={handleOnUpdate}
          clearFilters={handleClearFilters}
          getFilteredItems={handleGetFiltersWithTacticalAnalysisId}
        />
      </FiltersModalContent>
    </Modal>
  );
};

export default FiltersModal;
