import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import { usePlayerWithDefaults } from 'api/player/usePlayerWithDefaults';
import { PlayerProfileHeaderFeature } from 'features/dashboard/player-profile-header/PlayerProfileHeader.feature';
import { PlayerCompetitionsFeature } from 'features/dashboard/PlayerCompetitions.feature';
import { Dialog } from 'shared/components/dialog';
import { useGenerateTemporaryPlaylist } from 'shared/hooks/useGenerateTemporaryPlaylist';
import { Playlist } from 'shared/types';
import { PlayerProfileDashboardWidget } from 'widgets/dashboard/PlayerProfileDashboard.widget';
import { TemporaryPlaylistWidget } from 'widgets/playlist/temporary-playlist/TemporaryPlaylist.widget';

type Props = {
  playerId: string;
};

export const PlayerProfile = ({ playerId }: Props) => {
  const { data: player } = usePlayerWithDefaults(playerId);

  const [selectedSeasonYear, setSelectedSeasonYear] = useState<number | undefined>(player.playerDefaults?.season?.year);
  const [selectedCompetition, setSelectedCompetition] = useState<string | undefined>(
    player.playerDefaults?.season?.competition?.id,
  );

  const [temporalPlaylist, setTemporalPlaylist] = useState<Playlist | null>(null);
  const generateTemporaryPlaylist = useGenerateTemporaryPlaylist({ onSuccess: setTemporalPlaylist });

  useEffect(() => {
    setSelectedSeasonYear(player.playerDefaults?.season?.year);
    setSelectedCompetition(player.playerDefaults?.season?.competition?.id);
  }, [player]);

  return (
    <>
      <Stack gap={2} padding={(theme) => theme.spacing(1.5, 4, 2, 4)}>
        <PlayerProfileHeaderFeature />

        <PlayerCompetitionsFeature
          selectedSeasonYear={selectedSeasonYear}
          selectedCompetition={selectedCompetition}
          onChangeSelectedCompetition={setSelectedCompetition}
          onChangeSelectedSeasonYear={setSelectedSeasonYear}
        />
      </Stack>

      {selectedCompetition && selectedSeasonYear && (
        <>
          <PlayerProfileDashboardWidget
            key={`${selectedCompetition}-${selectedSeasonYear}`}
            competitionId={selectedCompetition}
            seasonYear={selectedSeasonYear}
            player={player}
            onEvent={generateTemporaryPlaylist}
          />
          <Dialog onClose={() => setTemporalPlaylist(null)} open={!!temporalPlaylist} fullWidth maxWidth={false}>
            <Box
              height={(theme) => `calc(100vh - ${theme.spacing(8)})`}
              width={(theme) => `calc(100vw - ${theme.spacing(8)})`}
            >
              {temporalPlaylist && <TemporaryPlaylistWidget playlist={temporalPlaylist} />}
            </Box>
          </Dialog>
        </>
      )}
    </>
  );
};
