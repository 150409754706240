import { array, boolean, number, object, string, z } from 'zod';

export const defaultCompetitionSchema = object({
  id: string(),
  name: string(),
  country: string(),
  seasons: array(
    object({
      id: string(),
      year: number(),
      crossesYear: boolean(),
      name: string(),
    }),
  ),
});

export type DefaultCompetition = z.infer<typeof defaultCompetitionSchema>;
