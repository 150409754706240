import { array, boolean, number, object, string, z } from 'zod';

export const defaultSeasonSchema = object({
  id: string(),
  year: number(),
  crossesYear: boolean(),
  name: string(),
  competition: object({
    id: string(),
    name: string(),
    country: string(),
  }),
  stages: array(
    object({
      id: string(),
      name: string(),
      orderIndex: number().nullable(),
      childStages: array(
        object({
          id: string(),
          name: string(),
          orderIndex: number().nullable(),
        }),
      ),
      parentStage: object({
        id: string(),
        name: string(),
        orderIndex: number().optional().nullable(),
      })
        .optional()
        .nullable(),
    }),
  ),
});

export type DefaultSeason = z.infer<typeof defaultSeasonSchema>;
