import { UseSuspenseQueryResult } from '@tanstack/react-query';

import { useFetchSuspenseRequest } from 'api/hooks/useFetchSuspenseRequest';
import { teamWithDefaults } from 'api/routes';
import { transformTeam } from 'pages/backoffice/api/teams/transformers';
import { TeamWithPlayers } from 'shared/types/team/team';

const TEAM_WITH_DEFAULTS_QUERY_KEY = 'team-with-defaults';

export const useTeamWithDefaults = (teamId: string): UseSuspenseQueryResult<TeamWithPlayers> => {
  return useFetchSuspenseRequest<TeamWithPlayers>({
    url: teamWithDefaults(teamId),
    queryRef: [TEAM_WITH_DEFAULTS_QUERY_KEY, teamId],
    transformer: transformTeam,
  });
};
