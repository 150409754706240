import { Paper, Stack, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { memo } from 'react';
import { useParams } from 'react-router-dom';

import { useTeamWithDefaults } from 'entities/dashboard/api/useTeamWithDefaults';
import { Country } from 'features/dashboard/team-profile-header-feature/ui/Country';
import { TeamLogo } from 'shared/components/team-logo';
import { invariant } from 'shared/utils/invariant';

const HEADER_MAX_WIDTH = '500px';
const HEADER_MIN_WIDTH = '316px';

export const TeamProfileHeaderFeature = memo(() => {
  const { teamId } = useParams();
  invariant(teamId);
  const { data: team } = useTeamWithDefaults(teamId);

  return (
    <Stack direction={'row'}>
      <Paper elevation={0} sx={{ padding: 3, maxWidth: HEADER_MAX_WIDTH, minWidth: HEADER_MIN_WIDTH }}>
        <Stack direction={'row'} gap={2} alignItems={'center'}>
          <TeamLogo size={'xlarge'} src={team.logoUrl} />
          <Stack gap={1}>
            <Typography fontSize={fontSizes.medium} fontWeight={(theme) => theme.typography.fontWeightBold}>
              {team.name}
            </Typography>
            <Country countryCode={team.countryCode} />
            {team.teamDefaults ? <Typography variant={'body2'}>{team.teamDefaults.competition.name}</Typography> : null}
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
});

TeamProfileHeaderFeature.displayName = 'TeamProfileHeaderFeature';
