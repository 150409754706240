import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CountryFlag } from 'shared/components/CountryFlag';
import { CountryCode } from 'shared/utils/countries';

type Props = {
  countryCode: CountryCode | undefined;
};

export const Country = ({ countryCode }: Props) => {
  const { t } = useTranslation('countries');
  if (!countryCode) return null;

  return (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      <CountryFlag countryCode={countryCode} />
      <Typography variant={'body2'}>{t(countryCode.toUpperCase())}</Typography>
    </Stack>
  );
};
