import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useCallback, useEffect } from 'react';
import { generatePath, matchPath, useNavigate, useParams } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { newGameData } from 'pages/backoffice/pages/games/game-form/atoms';
import { GameInfoSchema } from 'pages/backoffice/pages/games/game-form/forms';
import { NewGameTeam } from 'pages/backoffice/pages/games/game-form/types';
import { MatchTeamTypes } from 'shared/types/match';

export const NEW_GAMES_STEPS = ['home-team', 'away-team', 'game-info'] as const;

export const NEW_GAME_FORM_STEPS = [
  routes.BACKOFFICE_GAME_FORM_STEP_1,
  routes.BACKOFFICE_GAME_FORM_STEP_2,
  routes.BACKOFFICE_GAME_FORM_STEP_3,
  routes.BACKOFFICE_NEW_GAME_SUMMARY,
];

export const FORM_IDS = ['home-team-form', 'opponent-team-form', 'game-info-form'];

export const getCurrentStepFromPathName = (pathname: string) => {
  if (matchPath({ path: NEW_GAME_FORM_STEPS[0] }, pathname)) return 0;
  if (matchPath({ path: NEW_GAME_FORM_STEPS[1] }, pathname)) return 1;
  if (matchPath({ path: NEW_GAME_FORM_STEPS[2] }, pathname)) return 2;
  if (matchPath({ path: NEW_GAME_FORM_STEPS[3] }, pathname)) return 3;

  return 0;
};
export const useIsCreateGameStepValid = () => {
  const gameData = useAtomValue(newGameData);

  return useCallback(
    (step: number) => {
      if (step === 0) return true;
      if (gameData && gameData[MatchTeamTypes.HOME] && step === 1) return true;
      if (gameData && gameData[MatchTeamTypes.HOME] && gameData[MatchTeamTypes.OPPONENT] && step === 2) return true;

      return false;
    },
    [gameData],
  );
};

export const useIsUpdateCreateGameStepValid = () => {
  const gameData = useAtomValue(newGameData);

  return useCallback(() => {
    return Boolean(gameData && gameData[MatchTeamTypes.HOME] && gameData[MatchTeamTypes.OPPONENT] && gameData.gameInfo);
  }, [gameData]);
};

export const useCheckValidateStep = (step: number) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const checkIsCreateGameStepValid = useIsCreateGameStepValid();
  const checkIsUpdateGameStepValid = useIsUpdateCreateGameStepValid();

  useEffect(() => {
    !id && !checkIsCreateGameStepValid(step) && navigate(generatePath(routes.BACKOFFICE_GAME_FORM_STEP_1));
    id && !checkIsUpdateGameStepValid() && navigate(generatePath(routes.BACKOFFICE_EDIT_GAME_FORM, { id }));
  }, [id, checkIsUpdateGameStepValid, checkIsCreateGameStepValid, step, navigate]);
};

export function useTeamData(teamType: MatchTeamTypes): NewGameTeam | undefined {
  const gameData = useAtomValue(newGameData);

  return gameData ? gameData[teamType] : undefined;
}

export function useGameInfo(): GameInfoSchema | undefined {
  const gameData = useAtomValue(newGameData);

  return gameData ? gameData.gameInfo : undefined;
}

export function useSetTeamData(teamType: MatchTeamTypes) {
  const setNewGameData = useSetAtom(newGameData);

  return useCallback(
    (teamData: NewGameTeam) => {
      setNewGameData((gameData) => {
        return { ...gameData, [teamType]: teamData };
      });
    },
    [teamType, setNewGameData],
  );
}

export function useResetGameFormData() {
  return useResetAtom(newGameData);
}

export function useSetGameInfo() {
  useResetAtom(newGameData);
  const [gameData, setNewGameData] = useAtom(newGameData);

  return useCallback(
    (gameInfo: GameInfoSchema) => setNewGameData({ ...gameData, gameInfo }),
    [gameData, setNewGameData],
  );
}

export function useSetGameData() {
  return useSetAtom(newGameData);
}
