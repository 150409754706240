import { styled, Tooltip } from '@mui/material';
import React from 'react';

import IconShield from 'shared/components/icons/icon-shield';
import styles from 'shared/components/team-logo/TeamLogo.module.scss';

export type TeamLogoSizes = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

const LOGO_SIZES = {
  xsmall: '24px',
  small: '36px',
  medium: '48px',
  large: '64px',
  xlarge: '88px',
};

type Props = {
  src: string | null | undefined;
  name?: string;
  size?: TeamLogoSizes;
};

interface TeamLogoProps {
  size: TeamLogoSizes;
}

const LogoImg = styled('img', {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'withBorder',
})<TeamLogoProps>(({ size = 'small' }) => ({
  height: LOGO_SIZES[size],
}));

const LogoContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'withBorder',
})<TeamLogoProps>(({ size = 'small' }) => ({
  width: LOGO_SIZES[size],
  height: LOGO_SIZES[size],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const NoLogoImg = styled(IconShield, {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'withBorder',
})<TeamLogoProps>(({ theme, size = 'xsmall' }) => ({
  marginRight: theme.spacing(1),
  width: LOGO_SIZES[size],
  height: LOGO_SIZES[size],
}));

export const TeamLogo = ({ src, name, size = 'xsmall' }: Props) => {
  return (
    <Tooltip title={name ?? ''}>
      <LogoContainer size={size}>
        {src ? (
          <LogoImg size={size} alt={name} src={src} />
        ) : (
          <NoLogoImg size='small' color='secondary' className={styles.icon} />
        )}
      </LogoContainer>
    </Tooltip>
  );
};
