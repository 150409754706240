import { Stack, Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import { useState } from 'react';

import { EditTeamModal } from 'pages/backoffice/pages/teams/components/edit-team-modal';
import { TeamDetailsModal } from 'pages/backoffice/pages/teams/components/team-details-modal';
import { TeamListItem } from 'pages/backoffice/pages/teams/components/team-item/styled';
import { TeamMappingsModal } from 'pages/backoffice/pages/teams/components/team-mappings-modal';
import { IconTimeline } from 'shared/components/icons';
import IconEdit from 'shared/components/icons/icon-edit';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';
import { TeamLogo } from 'shared/components/team-logo';
import { Team } from 'shared/types/team/team';
import { Countries } from 'shared/utils/countries';

interface Props {
  team: Team;
}

export const TeamItem = ({ team }: Props) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isMappingsModalOpen, setIsMappingsModalOpen] = useState(false);
  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);
  const handleDetailsModalOpen = () => setIsDetailsModalOpen(true);
  const handleDetailsModalClose = () => setIsDetailsModalOpen(false);
  const handleMappingsModalOpen = () => setIsMappingsModalOpen(true);
  const handleMappingsModalClose = () => setIsMappingsModalOpen(false);

  const options: MenuListOption[] = [
    {
      displayText: 'Edit',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleEditModalOpen,
    },
    {
      displayText: 'Mappings',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleMappingsModalOpen,
    },
    {
      displayText: 'Details',
      icon: <IconTimeline size='small' color='secondary' />,
      onClick: handleDetailsModalOpen,
    },
  ];

  return (
    <>
      <TeamListItem key={team.id}>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <TeamLogo src={team.logoUrl ?? undefined} name={team.name} />
          <Typography fontWeight={fontWeight['500']}>{team.name}</Typography>
        </Stack>
        <Typography>{team.abbreviation}</Typography>
        <Typography>
          {Countries.find((item) => item.code.toUpperCase() === team.countryCode?.toUpperCase())?.name || ''}
        </Typography>
        <KebabMenu options={options} />
      </TeamListItem>
      {isEditModalOpen ? (
        <EditTeamModal open={isEditModalOpen} onClose={handleEditModalClose} team={team} teamId={team.id} />
      ) : null}
      {isDetailsModalOpen && team?.fixtures ? (
        <TeamDetailsModal teamMappings={team.mappings} teamFixtures={team.fixtures} onClose={handleDetailsModalClose} />
      ) : null}
      {isMappingsModalOpen ? <TeamMappingsModal team={team} onClose={handleMappingsModalClose} /> : null}
    </>
  );
};
