import { Box } from '@mui/material';

import { CountryCode } from 'shared/utils/countries';

type Props = {
  countryCode: CountryCode | undefined;
};

const FLAG_HEIGHT = 16;
const FLAG_WIDTH = FLAG_HEIGHT * 1.5;

export const CountryFlag = ({ countryCode }: Props) => {
  if (!countryCode) return null;

  return (
    <Box
      height={`${FLAG_HEIGHT}px`}
      minHeight={`${FLAG_HEIGHT}px`}
      width={`${FLAG_WIDTH}px`}
      minWidth={`${FLAG_WIDTH}px`}
      justifyContent={'center'}
      overflow={'hidden'}
      borderRadius={'2px'}
      sx={{
        backgroundImage: `url(/flags/3x2/${countryCode.toUpperCase()}.svg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
  );
};
