import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { Fragment } from 'react';

import { DialogNew } from 'shared/components/dialog-new';
import { IconUser } from 'shared/components/icons/icon-user';
import { useDates } from 'shared/hooks/use-dates';
import { TeamFixture, TeamMapping } from 'shared/types/team/team';

interface Props {
  onClose: () => void;
  teamFixtures: TeamFixture[];
  teamMappings?: TeamMapping[];
}

function createData(name: string, value: string) {
  return { name, value };
}

const TABLE_WIDTH = 650;

const TableData = ({ teamFixture }: { teamFixture: TeamFixture }) => {
  const { dateToString } = useDates();
  const rows = [
    createData('Name', teamFixture.name.toString()),
    createData('Duration', teamFixture.duration.toString()),
    createData('date', dateToString(teamFixture.date)),
    createData('Score', `${teamFixture.homeTeamScore} - ${teamFixture.awayTeamScore}`),
    createData('Pitch length', teamFixture.pitchLength.toString()),
    createData('Pitch width', teamFixture.pitchWidth.toString()),
  ];

  return (
    <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
      <Table sx={{ minWidth: TABLE_WIDTH }} aria-label='team details table'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align='right'>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component='th' scope='row'>
                {row.name}
              </TableCell>
              <TableCell align='right'>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MappingsTable = ({ teamMappings }: { teamMappings: TeamMapping[] }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: TABLE_WIDTH }} aria-label='Team mappings'>
        <TableHead>
          <TableRow>
            <TableCell>Provider</TableCell>
            <TableCell align='right'>Player provider id</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            {teamMappings.map((row) => (
              <Fragment key={row.provider}>
                <TableCell component='th' scope='row'>
                  {row.provider}
                </TableCell>
                <TableCell align='right'>{row.team_id_provider}</TableCell>
              </Fragment>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const TeamDetailsModal = ({ onClose, teamFixtures, teamMappings = [] }: Props) => {
  return (
    <DialogNew
      fullWidth
      maxWidth='md'
      title={'Team details'}
      icon={<IconUser />}
      onCancel={onClose}
      onClose={onClose}
      open={true}
    >
      <Stack gap={2}>
        <Typography variant='h6'>Mappings</Typography>
        {teamMappings.length === 0 ? (
          <Alert variant={'outlined'} severity={'warning'} sx={{ display: 'flex', justifyContent: 'center' }}>
            No mapping data
          </Alert>
        ) : (
          <MappingsTable teamMappings={teamMappings} />
        )}

        <Typography variant='h6'>Fixtures</Typography>
        {teamFixtures.length === 0 ? (
          <Alert variant={'outlined'} severity={'warning'} sx={{ display: 'flex', justifyContent: 'center' }}>
            No fixtures data
          </Alert>
        ) : (
          teamFixtures.map((fixture) => <TableData key={fixture.id} teamFixture={fixture} />)
        )}
      </Stack>
    </DialogNew>
  );
};
