import { Stack } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeletePlaylistItems } from 'api/playlist/useDeletePlaylistItems';
import { useDuplicatePlaylistItems } from 'api/playlist/useDuplicatePlaylistItems';
import { invalidatePlaylistQuery } from 'api/playlist/usePlaylist';
import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { PLAYLIST_TIMELINE_HEADER_HEIGHT } from 'entities/playlist/config/Playlist.config';
import { useMapVideos } from 'entities/playlist/hooks/use-map-videos/useMapVideos';
import {
  useBulkSelectedItems,
  useResetBulkSelectedItems,
  useSetBulkSelectedItems,
} from 'entities/playlist/hooks/useBulkSelectedItems';
import { ItemsListContainer } from 'entities/playlist/ui/ItemsListContainer';
import { PlaylistItemsEmpty } from 'entities/playlist/ui/PlaylistItemsEmpty';
import { DownloadPlaylistItemsModal } from 'features/playlist/download-playlist-items-modal/DownloadPlaylistItemsModal';
import { SelectedItemsCopyToDialog } from 'features/playlist/SelectedItemsCopyToDialog';
import { SortablePlaylistItemsList } from 'features/playlist/user-playlist-items-list/ui/SortablePlaylistItemsList';
import { TrimModal } from 'features/playlist/user-playlist-items-list/ui/trim-modal/TrimModal';
import { BulkButton } from 'shared/components/bulk-button/BulkButton';
import { CheckboxWithCustomColor } from 'shared/components/CheckboxWithCustomColor/CheckboxWithCustomColor';
import ConfirmPopoverDialog from 'shared/components/confirm-popover-dialog';
import IconClose from 'shared/components/icons/icon-close';
import IconCopy from 'shared/components/icons/icon-copy';
import { IconCopyTo } from 'shared/components/icons/icon-copy-to';
import IconDelete from 'shared/components/icons/icon-delete';
import { IconDownload } from 'shared/components/icons/icon-download';
import IconTrim from 'shared/components/icons/icon-trim';
import { ItemsListBulk } from 'shared/components/items-list-bulk/ItemsListBulk';
import { usePlaylistItems, useVideoPlayerActions } from 'shared/components/video-player';
import { useFeatureFlag } from 'shared/contexts/app-state/hooks/useFeatureFlag';
import { Playlist } from 'shared/types/index';

interface Props {
  onPlaylistOrderChange?: (currentVideoIndex: number, newVideoIndex: number) => void;
  playlist: Playlist;
  setTrimmingPlaylistItem: (videoId: string) => void;
  trimmingPlaylistItemId?: string;
  enabledBulkMode?: boolean;
  setEnabledBulkMode?: (value: boolean) => void;
}

export const UserPlaylistItemsList = ({
  playlist,
  setTrimmingPlaylistItem,
  trimmingPlaylistItemId,
  enabledBulkMode = false,
  setEnabledBulkMode = () => {},
}: Props) => {
  const { t } = useTranslation();
  const mapVideos = useMapVideos();
  const selectedItems = useBulkSelectedItems(playlist.id);
  const setSelectedItems = useSetBulkSelectedItems(playlist.id);
  const resetSelectedItems = useResetBulkSelectedItems(playlist.id);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [trimModalOpen, setTrimModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [copyToModalOpen, setCopyToModalOpen] = useState(false);
  const playlistItems = usePlaylistItems();
  const downloadPlaylistFeatureFlag = useFeatureFlag(FEATURE_FLAG.DOWNLOAD_PLAYLIST);

  const { duplicatePlaylistItems } = useDuplicatePlaylistItems(playlist.id);
  const { deletePlaylistItems } = useDeletePlaylistItems(playlist.id);
  const downloadRef = useRef<HTMLDivElement | null>(null);
  const copyToRef = useRef<HTMLDivElement | null>(null);
  const deleteRef = useRef<HTMLDivElement | null>(null);
  const actions = useVideoPlayerActions();

  useEffect(() => {
    if (selectedItems.length > 0 && !enabledBulkMode) {
      resetSelectedItems();
    }
  }, [enabledBulkMode, resetSelectedItems, selectedItems.length]);

  const playlistItemsIds = playlistItems.map((item) => item.id);

  const allItemsSelected = selectedItems.length === playlistItems.length;
  const selectAllIndeterminate = selectedItems.length > 0 && !allItemsSelected;

  const handleSelectAll = useCallback(() => {
    if (selectAllIndeterminate || allItemsSelected) {
      return resetSelectedItems();
    }

    return setSelectedItems(playlistItemsIds);
  }, [allItemsSelected, playlistItemsIds, resetSelectedItems, selectAllIndeterminate, setSelectedItems]);

  const handleTrimModalOpen = useCallback(() => setTrimModalOpen(true), []);
  const handleTrimModalClose = useCallback(() => setTrimModalOpen(false), []);

  const handleTrimClick = useCallback(() => handleTrimModalOpen(), [handleTrimModalOpen]);

  const handleDuplicateAll = useCallback(() => {
    duplicatePlaylistItems({
      playlistItemIds: selectedItems,
      options: {
        onSuccess: (playlist: Playlist) => {
          invalidatePlaylistQuery();
          actions.replacePlaylistItems(mapVideos(playlist));
          setEnabledBulkMode(false);
          resetSelectedItems();
        },
      },
    });
  }, [duplicatePlaylistItems, selectedItems, actions, mapVideos, setEnabledBulkMode, resetSelectedItems]);

  const handleDeleteAll = useCallback(() => {
    deletePlaylistItems(selectedItems, () => {
      invalidatePlaylistQuery();
      actions.removePlaylistItems(selectedItems);
      setEnabledBulkMode(false);
      resetSelectedItems();
    });
  }, [deletePlaylistItems, selectedItems, actions, setEnabledBulkMode, resetSelectedItems]);

  const handleSetDisabledBulkMode = useCallback(() => {
    setEnabledBulkMode(false);
  }, [setEnabledBulkMode]);

  const handleSetDownloadModalOpen = useCallback(() => {
    setIsDownloadModalOpen(true);
  }, [setIsDownloadModalOpen]);

  const handleCloseDeleteModal = useCallback(() => {
    setDeleteModalOpen(true);
  }, [setDeleteModalOpen]);

  const handleCloseCopyToModal = useCallback(() => {
    setCopyToModalOpen(true);
  }, [setCopyToModalOpen]);

  const handleOpenCopyToModal = useCallback(() => {
    setCopyToModalOpen(false);
    setEnabledBulkMode(false);
    resetSelectedItems();
  }, [setEnabledBulkMode, resetSelectedItems]);

  const handleOpenDeleteModal = useCallback(() => {
    setIsDownloadModalOpen(false);
  }, [setIsDownloadModalOpen]);

  return (
    <>
      {enabledBulkMode ? (
        <div>
          <ItemsListBulk height={PLAYLIST_TIMELINE_HEADER_HEIGHT}>
            <Stack direction='row' alignItems='center'>
              <BulkButton onClick={handleSetDisabledBulkMode} IconComponent={IconClose} />
              <Stack direction='row' alignItems='center'>
                <CheckboxWithCustomColor
                  customColor='white'
                  onClick={handleSelectAll}
                  checked={allItemsSelected}
                  indeterminate={selectAllIndeterminate}
                />
                {t('playlist-detail:all-clips')} ({selectedItems.length}/{playlistItems.length})
              </Stack>
            </Stack>

            <Stack direction='row' spacing={1}>
              {downloadPlaylistFeatureFlag ? (
                <div ref={downloadRef}>
                  <BulkButton
                    onClick={handleSetDownloadModalOpen}
                    IconComponent={IconDownload}
                    label={t('common:actions.download')}
                    disabled={isEmpty(selectedItems)}
                  />
                </div>
              ) : null}

              <BulkButton
                onClick={handleTrimClick}
                IconComponent={IconTrim}
                label={t('common:actions.trim')}
                disabled={isEmpty(selectedItems)}
              />

              <BulkButton
                onClick={handleDuplicateAll}
                IconComponent={IconCopy}
                label={t('common:actions.duplicate')}
                disabled={isEmpty(selectedItems)}
              />

              <div ref={copyToRef}>
                <BulkButton
                  onClick={handleCloseCopyToModal}
                  IconComponent={IconCopyTo}
                  label={t('common:actions.copy-to')}
                  disabled={isEmpty(selectedItems)}
                />
              </div>

              <div ref={deleteRef}>
                <BulkButton
                  onClick={handleCloseDeleteModal}
                  IconComponent={IconDelete}
                  label={t('common:actions.delete')}
                  disabled={isEmpty(selectedItems)}
                />
              </div>

              {isDownloadModalOpen ? (
                <DownloadPlaylistItemsModal
                  downloadType={'playlistItems'}
                  playlistData={{
                    ...playlist,
                    playlistItems: playlist.playlistItems.filter((item) => selectedItems.includes(item.id)),
                  }}
                  onClose={handleOpenDeleteModal}
                />
              ) : null}

              {trimModalOpen ? (
                <TrimModal playlistId={playlist.id} itemsToTrim={selectedItems} onClose={handleTrimModalClose} />
              ) : null}
            </Stack>
          </ItemsListBulk>
          {deleteModalOpen ? (
            <ConfirmPopoverDialog
              anchorEl={deleteRef.current}
              cancelLabel={t('common:actions.cancel')}
              confirmLabel={t('common:actions.remove')}
              description={t('playlist-detail:confirm-permanently-remove-clips')}
              isOpen={deleteModalOpen}
              onConfirm={handleDeleteAll}
              setIsOpen={setDeleteModalOpen}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            />
          ) : null}

          {copyToModalOpen && (
            <SelectedItemsCopyToDialog
              selectedItems={selectedItems}
              playlist={playlist}
              onClose={handleOpenCopyToModal}
              anchor={copyToRef.current}
            />
          )}
        </div>
      ) : null}
      <ItemsListContainer>
        {isEmpty(playlistItems) ? (
          <PlaylistItemsEmpty />
        ) : (
          <SortablePlaylistItemsList
            playlist={playlist}
            playlistItems={playlistItems}
            setTrimmingPlaylistItem={setTrimmingPlaylistItem}
            trimmingPlaylistItemId={trimmingPlaylistItemId}
          />
        )}
      </ItemsListContainer>
    </>
  );
};
