import { Button, Grid } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreatePlaylist } from 'api/playlist/useCreatePlaylist';
import { invalidatePlaylistsQuery } from 'api/playlist/useFetchPlaylists';
import { Dialog } from 'shared/components/dialog';
import { DialogActions } from 'shared/components/dialog/dialog-actions';
import { DialogContent } from 'shared/components/dialog/dialog-content';
import { DialogHeader } from 'shared/components/dialog/dialog-header';
import IconAddFolder from 'shared/components/icons/icon-add-folder';
import IconPlus from 'shared/components/icons/icon-plus';
import Input from 'shared/components/input';
import { NotificationType, useNotifications } from 'shared/hooks/notifications';

interface Props {
  className?: string;
}

export const CreatePlaylist = ({ className = '' }: Props) => {
  const { t } = useTranslation();
  const [isCreatePlaylistOpen, setIsCreatePlaylistOpen] = useState(false);
  const { createPlaylist } = useCreatePlaylist();
  const triggerNotification = useNotifications();
  const [playlistNameValue, setPlaylistNameValue] = useState('');

  const id = isCreatePlaylistOpen ? 'create-playlist-dialog' : undefined;

  const handleAddClipsClick = useCallback(() => {
    setIsCreatePlaylistOpen((isCreatePlaylistOpen) => !isCreatePlaylistOpen);
  }, [setIsCreatePlaylistOpen]);

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      if (!playlistNameValue.trim()) {
        return triggerNotification({
          type: NotificationType.ERROR,
          message: t('playlists:forms.add-a-name-for-the-playlist'),
        });
      }

      createPlaylist({
        data: { name: playlistNameValue as string },
        onSuccess: () => invalidatePlaylistsQuery(),
      });
      setPlaylistNameValue('');
      setIsCreatePlaylistOpen(false);
    },
    [createPlaylist, playlistNameValue, triggerNotification, t, setPlaylistNameValue],
  );

  const handleClose = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsCreatePlaylistOpen(false);
  }, []);

  const handleInputChange = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    setPlaylistNameValue(event.currentTarget.value);
  }, []);

  return (
    <Grid item className={className}>
      <Button
        variant='contained'
        aria-describedby={id}
        onClick={handleAddClipsClick}
        startIcon={<IconPlus size='small' sx={{ color: 'common.white' }} />}
        data-testid='playlists-page-create-playlist-button'
      >
        {t('playlists:forms.create-playlist')}
      </Button>
      <Dialog id={id} keepMounted={false} onClose={handleClose} open={isCreatePlaylistOpen} maxWidth='xs' fullWidth>
        <form onSubmit={handleSubmit} data-testid='create-playlist-modal-form'>
          <DialogHeader icon={<IconAddFolder size='small' />}>{t('playlists:forms.new-playlist')}</DialogHeader>
          <DialogContent>
            <Grid container spacing={2} direction={'column'}>
              <Grid item xs={12} container alignItems={'center'}>
                <Input
                  autoFocus
                  fullWidth
                  id='playlist-name'
                  label={t('playlists:forms.playlist-name')}
                  onChange={handleInputChange}
                  value={playlistNameValue}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type={'submit'} variant='contained' data-testid='create-playlist-modal-button'>
              {t('playlists:forms.create-playlist')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Grid>
  );
};
